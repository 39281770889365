.homePage {
    background: #F7F9FF;
}

.banner {
    background-image: url('../../static/images/home/banner1.png');
    background-size: 100% 100%;
    background-repeat: no-repeat;
    height: 5rem;
}

.banner1 {
    background-image: url('../../static/images/home/banner2.png');
    background-size: 100% 100%;
    background-repeat: no-repeat;
    height: 5rem;
    position: relative;
}

.banner1 .left .btn {
    width: 1.40rem;
    height: 0.40rem;
    border: 1px solid #3E71FD;
    border-radius: 0.32rem;
    font-size: 0.18rem;
    font-family: Alibaba PuHuiTi 2.0;
    font-weight: normal;
    color: #4290FA;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 0.15rem;
    position: absolute;
    right: 0;
    cursor: pointer;
}

.banner1 .left {
    position: absolute;
    right: 10.22rem;
    top: 2.81rem;
    text-align: right;
}

.banner1 .left .text1 {
    font-size: 0.43rem;
    font-weight: normal;
    line-height: 0.41rem;
    color: #333333;
}

.banner1 .left .text2 {
    font-size: 0.29rem;
    font-weight: normal;
    line-height: 0.41rem;
    color: #333333;
}

.banner1 .left .text2 span {
    font-weight: 600;
}



.banner1 .right {
    position: absolute;
    left: 10.23rem;
    top: 0.69rem;
}

.banner1 .right .btn {
    width: 1.40rem;
    height: 0.40rem;
    border: 1px solid #3E71FD;
    border-radius: 0.32rem;
    font-size: 0.18rem;
    font-weight: normal;
    color: #4290FA;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 0.28rem;
    cursor: pointer;
}

.banner1 .right .text1 {
    font-size: 0.43rem;
    font-weight: normal;
    line-height: 0.41rem;
    color: #333333;
}

.banner1 .right .text2 {
    font-size: 0.29rem;
    font-weight: normal;
    line-height: 0.41rem;
    color: #333333;
}

.banner1 .right .text2 span {
    font-weight: 600;
}



.banner2 {
    background-image: url('../../static/images/home/banner3.png');
    background-size: 100% 100%;
    background-repeat: no-repeat;
    height: 5rem;
    text-align: center;
    position: relative;
}

.banner2 .title {
    font-size: 0.40rem;
    font-weight: normal;
    line-height: 0.56rem;
    color: #FFFFFF;
    padding-top: 0.72rem;
    margin-bottom: 0.12rem;
}

.banner2 .title2 {
    font-size: 0.25rem;
    font-weight: normal;
    line-height: 0.34rem;
    color: #FFFFFF;
}

.banner2 .btn {
    width: 1.19rem;
    height: 0.39rem;
    background: #FFFFFF;
    border-radius: 0.32rem;
    font-size: 0.18rem;
    font-weight: normal;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    position: absolute;
    bottom: 0.52rem;
    left: 0;
    right: 0;
    margin: 0 auto;
}

.banner3 {
    background-image: url('../../static/images/home/banner4.png');
    background-size: 100% 100%;
    background-repeat: no-repeat;
    height: 5rem;
    text-align: center;
    position: relative;
    padding: 0 3.6rem;
}

.banner3 .title {
    font-size: 0.24rem;
    font-weight: normal;
    line-height: 0.33rem;
    color: #4E4E4E;
    padding-top: 0.92rem;
    padding-bottom: 0.61rem;
}

.homeSwiperImg{
    height: 2.2rem;
}

.jiantou{
    width: 0.17rem;
    height: 0.32rem;
    cursor: pointer;
}

.jiantou:first-child{
    margin-right: 0.34rem;
    transform: rotate(180deg);
}

.jiantou:last-child{
    margin-left: 0.34rem;
}