@import "./iconfont.css";
@import "./flex.css";

@font-face {
    font-family: 'Alibaba PuHuiTi';
    src: url('./fonts/AlibabaPuHuiTi-2-55-Regular.ttf'), url("./fonts/AlibabaPuHuiTi-2-65-Medium.ttf"), url("./fonts/AlibabaPuHuiTi-2-85-Bold.ttf"),
        url("./fonts/AlibabaPuHuiTi-2-75-SemiBold.ttf"), url("./fonts/AlibabaPuHuiTi-2-45-Light.ttf"),
        url("./fonts/AlibabaPuHuiTi-2-35-Thin.ttf")
}


html,
body,
#root {
    margin: 0;
    background-color: #fff !important;
    width: 100%;
    height: 100%;
    font-family: "Alibaba PuHuiTi", PingFang SC;
}

* {
    box-sizing: border-box;
}

input {
    -webkit-appearance: none;
}

/*sidebar*/
.ant-layout-sider-children {
    height: calc(100vh - 1.16rem) !important;
    box-shadow: 0px 0px 20px rgba(205, 213, 235, 0.27);
    border-radius: 0.06rem;
}

.ant-layout-sider {
    background: none !important;
}

/* 布局 */

.layout {
    background: linear-gradient(141deg, rgba(99, 138, 246, 1) 0%, rgba(77, 121, 243, 1) 100%);
    box-shadow: 0px 2px 4px 0px rgba(175, 175, 175, 0.5);
}

/* swiper */

.swiper-slide{
    width: auto !important;
}